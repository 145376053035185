class Access {
  constructor({
    getUser,
    getSubscriptions,
    getLimitsOwner,
    getLimitsMembers,
    throwFunction,
  }) {
    this.getUser = getUser;
    this.getSubscriptions = getSubscriptions;
    this.getLimitsOwner = getLimitsOwner;
    this.getLimitsMembers = getLimitsMembers;
    this.throwFunction = throwFunction;
  }
  getUser = () => null;
  getSubscriptions = () => null;
  getLimitsOwner = () => null;
  getLimitsMembers = () => null;
  throwFunction = () => {
    throw new Error("AccessDenied");
  };

  cache = {};

  get user() {
    if ("user" in this.cache) {
      return this.cache.user;
    }
    const result = this.getUser();
    if (result instanceof Promise) {
      result.then((v) => (this.cache.user = v));
    }
    return result;
  }
  get subscriptions() {
    if ("subscriptions" in this.cache) {
      return this.cache.subscriptions;
    }
    const result = this.getSubscriptions();
    if (result instanceof Promise) {
      result.then((v) => (this.cache.subscriptions = v));
    }
    return result;
  }

  messages = {
      authorizedFunctional:
          "Данный функционал доступен только для зарегистрированных пользователей",
      noSubscription:
          "Данный функционал доступен только для пользователей с оплаченной подпиской",
      weakPlan:
          "Данный функционал не доступен на вашем тарифном плане",
      weakPlan_Plugin:
          "Plugin: Данный функционал не доступен на вашем тарифном плане",
  };

  getErrorDefinition(type) {
    return {
      type,
      text: this.messages[type],
    };
  }

  notFree = [
    ["user", "subscriptions"],
    ({ user = null, subscriptions }) => {
      if (!user) {
        return this.getErrorDefinition("authorizedFunctional");
      }
      if (!subscriptions.length) {
        return this.getErrorDefinition("noSubscription");
      }

      /**/  //self-demo
      if (
        !subscriptions.some((subscription) => subscription.plan.name !== "Demo")
      ) {
        return this.getErrorDefinition("noSubscription");
      }
      /**/

      return null;
    },
  ];

  All = [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => { // eslint-disable-line
          return null;
      },
  ];


  rights = {
    // Раздел списков, функционал добавления в списки
    lists: this.notFree,
    listMs: this.notFree,

    priceVarList: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions }) => {
            if (!user) {
                return this.getErrorDefinition("authorizedFunctional");
            }
            if (!subscriptions.length) {
                return this.getErrorDefinition("noSubscription");
            }

            /**/ //self-demo
            if (
                !subscriptions.some(
                    (subscription) => subscription.plan.name !== "Demo"
                )
            ){
                return this.getErrorDefinition("noSubscription");
            }
            /**/

            if( !subscriptions.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
                !subscriptions.some((i) => [6].includes(i.plan.id))
            ){
                return this.getErrorDefinition("weakPlan");
            }

            return null;
        },
    ],

    // Раздел подключений
    connections: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }
        return null;
      },
    ],

    repricer: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }
        return null;
      },
    ],

    reviewManagement: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }
        return null;
      },
    ],

    seo: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }
        return null;
      },
    ],

    // Экспорт табличных данных
    export: this.notFree,

    export_Plugin: this.All,

    ratings: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }

        /**/ //self-demo
        if (
          !subscriptions.some(
            (subscription) => subscription.plan.name !== "Demo"
          )
        ) {
          return this.getErrorDefinition("noSubscription");
        }
        /**/

        if( !subscriptions.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
            !subscriptions.some((i) => [6].includes(i.plan.id))
        ){
          return this.getErrorDefinition("weakPlan");
        }
        return null;
      },
    ],

    mp: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions, params: { mp } }) => {
        if (!user || !subscriptions?.length) {
          return null;
        }

        //console.log('11: subscriptions=',subscriptions)

        /**/  //self-demo
        if (
          !subscriptions.some(
            (subscription) => subscription?.plan.name !== "Demo"
          )
        ) {
          return null;
        }
        /**/

        if (
          String(mp).toLowerCase().slice(0,2)==="oz" &&
          !subscriptions?.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
          !subscriptions?.some((i) => [6].includes(i.plan.id))
        ){
          return this.getErrorDefinition("weakPlan");
        }
        return null;
      },
    ],

    mp_Keyword: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions, params: { mp } }) => {
            if (!user || !subscriptions?.length) {
                return null;
            }

            ////if(global.E){E('mp_Keyword: subscriptions[0]=',subscriptions[0])}

            /**/  //self-demo
            if (
                !subscriptions.some(
                (subscription) => subscription?.plan.name !== "Demo"
                )
            ) {
                return null;
            }
            /**/

            if (
                String(mp).toLowerCase().slice(0,2)==="oz" &&
                !subscriptions?.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
                !subscriptions?.some((i) => [6].includes(i.plan.id))
            ){
                return this.getErrorDefinition("weakPlan");
            }

            return null;
        },
    ],

    mp_Plugin: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions, params: { mp } }) => { // eslint-disable-line
            //free
            if( user && !subscriptions?.length ){
                return null;
            }

            if (
              String(mp).toLowerCase().slice(0,2)==="oz" &&
              !subscriptions?.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
              !subscriptions?.some((i) => [6].includes(i.plan.id))
            ){
                return this.getErrorDefinition("weakPlan_Plugin");
            }

            return null;
        },
    ],

    listContent: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions, params: { mp } }) => {
        if (!user) {
          return this.getErrorDefinition("authorizedFunctional");
        }
        if (!subscriptions.length) {
          return this.getErrorDefinition("noSubscription");
        }

        /**/  // self-demo
        if (
          !subscriptions.some(
            (subscription) => subscription?.plan.name !== "Demo"
          )
        ) {
          return this.getErrorDefinition("noSubscription");
        }
        /**/

        if (
          String(mp).toLowerCase().slice(0,2)==="oz" &&
          !subscriptions?.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
          !subscriptions?.some((i) => [6].includes(i.plan.id))
        ){
          return this.getErrorDefinition("weakPlan");
        }
        return null;
      },
    ],

    // ~ listContent
    RrcContent: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions}) => {
            if (!user) {
                return this.getErrorDefinition("authorizedFunctional");
            }
            if (!subscriptions.length) {
                return this.getErrorDefinition("noSubscription");
            }

            /**/  // self-demo
            if( !subscriptions?.some(
                  (subscription) => subscription?.plan.name !== "Demo"
                )
            ){
                return this.getErrorDefinition("noSubscription");
            }
            /**/

            if(
                ////String(mp).toLowerCase().slice(0,2)==="oz" &&
                !subscriptions.some((i) => [2, 3, 5].includes(i.plan.main_position)) &&
                !subscriptions.some((i) => [6].includes(i.plan.id))
            ){
                return this.getErrorDefinition("weakPlan");
            }
            return null;
        },
    ],

    filterPresets: this.notFree,
    queriesSelection: this.notFree,
  };

  limits = {
    // Доступный временной промежуток отчета
    reportDays: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 30;
        }
        return subscriptions?.reduce((acc, subscription) => {
          if (subscription?.plan.report_days === null || acc === null) {
            return null;
          }
          if (subscription?.plan.report_days > acc) {
            return subscription?.plan.report_days;
          }
          return acc;
        }, 0);
      },
    ],

    // Ограничение на доступное кол-во записей в отчете
    // Первые n строк будут доступны
    // Учитывается номер и размер страницы при запросе отчета
    reportTableLimit: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 20;
        }

        ////console.log('2: subscriptions=', subscriptions)

        /**/ //self-demo
        if (
          !subscriptions?.some(
            (subscription) => subscription?.plan.name !== "Demo"
          )
        ) {
          return 20;
        }
        /**/

        return null;
      },
    ],

    // Кол-во отчетов за последние сутки, считается по таблице user_visit
    reportTableRequestsPerDay: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {

        if( !user || !subscriptions?.length ){
          return 3;
        }
        return subscriptions?.reduce(
          (acc, subscription) => subscription?.plan.requests_quota,
          0
        );
      },
    ],

    // 2024-05-17: Число отчетов KeywordInfo за последние сутки, считается по таблице user_visit
    keywordOverviewAll_PerDayLimit: [
    //getKeyword_PerDayLimit: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions }) => {
            if (!user || !subscriptions?.length) {
                return 0;
            }

            ////console.log('361: subscriptions=',subscriptions)

            return subscriptions?.reduce(
                (acc, subscription) => subscription?.plan.keywordOverviewAll_PerDayLimit,
                //(acc, subscription) => subscription.plan.getKeyword_PerDayLimit,
                0
            );
        },
    ],

    // для числа обновлений цен в RRC
    // считается по таблице CH.priceVar_fresh
    rrc_quota: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions }) => {

            /* //api
            if(global.E){
                const _F='rrc_quota';
                global.E(_F,'374: user=',user)
                global.E(_F,'374: subscriptions=',subscriptions)
            }
            */

            if (!user || !subscriptions?.length) {
                return 0;
            }
            return subscriptions.reduce(
                (acc, subscription) => subscription?.plan.rrc_quota,
                0
            );
        },
    ],

    repricersku_quota: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
          if (!user || !subscriptions?.length) {
              return 0;
          }
          return subscriptions?.reduce(
              (acc, subscription) => subscription?.plan.repricersku_quota,
              0
          );
      },
  ],

    // Кол-во токенов
    tokens: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 0;
        }
        return subscriptions.reduce(
          (acc, subscription) => subscription.plan.tokens_quota,
          0
        );
      },
    ],

    keywords: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 0;
        }
        return subscriptions.reduce(
          (acc, subscription) => subscription.plan.keywords_quota,
          0
        );
      },
    ],

    //! + добавить поле в api/src/models/Plan.js : class Plan extends Model ...
    //&: мониторинги
    monitors: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 0;
        }
        return subscriptions.reduce(
          (acc, subscription) => subscription.plan.monitors_quota,
          0
        );
      },
    ],


    //&: limit: плагин
    plugin: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions }) => {

            if (!user || !subscriptions?.length) {
                //return 3;
                return 5000; // = pro quota
            }

            return subscriptions.reduce(
                (acc, subscription) => subscription.plan.plugin_quota,
                0
            );
        },
    ],


    reviewManagement: [
      ["user", "subscriptions"],
      ({ user = null, subscriptions }) => {
        if (!user || !subscriptions?.length) {
          return 3;
        }
        return subscriptions.reduce(
          (acc, subscription) => subscription.plan.reviewscript_quota,
          0
        );
      },
    ],

    /* OLD:
    concurrentSessions: () => {
      return 1;
    },
    */
    //&: 2024-12-19
    ////ALTER TABLE `plan_20240522` add `concurrentsessions_quota` int(11) not NULL DEFAULT 1;
    ////ALTER TABLE `user_plan` add `concurrentsessions_quota` int(11) NULL DEFAULT NULL;
    concurrentSessions: [
        ["user", "subscriptions"],
        ({ user = null, subscriptions }) => { // eslint-disable-line

            ////console.log('566: user=',user)
            if( [3419].includes(user.user_id) ){
                return 10;
            }
            return 1;

            /* это на потом : в развитие темы 'concurrentsessions_quota'
            if (!user || !subscriptions?.length) {
                return 1;
            }
            return subscriptions.reduce(
                (acc, subscription) => subscription.plan.concurrentsessions_quota,
                1
            );
            */
        },
    ],

  };

  processNode(
    key,
    params = {},
    config = null,
    category = "rights",
    wrap = (v) => v
  ) {
  ////const _F='processNode';

    const node = this[category][key];


    /*
    if( global.E ){ //api
        //E('access.540: category=',category)
        //E('access.540: key=',key)
        //E('access.540: node=',node)
    }
    */

    let deps = [];
    let checkFunction = node;
    if (Array.isArray(node)) {
      [deps, checkFunction] = node;
    }
    if (config) {
      return wrap(checkFunction({ ...config, params }));
    }

    const resolvingDeps = deps.map((dep) => this[dep]);
    if (resolvingDeps.some((i) => i instanceof Promise)) {
      return Promise.all(resolvingDeps).then((resolvedList) => {
        const resolvedDeps = {};
        resolvedList.forEach((value, index) => {
          resolvedDeps[deps[index]] = value;
        });
        return wrap(
          checkFunction({
            ...resolvedDeps,
            params,
          })
        );
      });
    }

    const resolvedDeps = {};
    resolvingDeps.forEach((value, index) => {
      resolvedDeps[deps[index]] = value;
    });

    return wrap(
      checkFunction({
        ...resolvedDeps,
        params,
      })
    );
  }

  check(key, params = {}, config = null) {
    return this.processNode(key, params, config, "rights", (v) => v);
  }

  getError(key, params = {}, config = null) {
    return this.processNode(key, params, config, "rights", (v) => v);
  }

  getLimit(key, params = {}, config = null) {
  ////const _F='getLimit';
  ////global.E(global._T(_F), 'key=',key)

    return this.processNode(key, params, config, "limits");
  }

  hasOrThrow(key, params) {
    const accessError = this.getError(key, params);
    if (accessError instanceof Promise) {
      return accessError.then((resolvedAccessError) => {
        if (resolvedAccessError) {
          this.throwFunction({ ...resolvedAccessError, key });
        }
      });
    }
    if (accessError) {
      this.throwFunction({ ...accessError, key });
    }
  }
}

module.exports = Access;
