import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    },
    NA: {
      type: String,
      default: "н/д"
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMCategoriesNewSellersDetail", {
        ...query,
        ...this.reportSettings
      });
    },
    href_s_id_BZ(item) {
      let href = "/" + (this.list.mp == "wb" ? "ozon" : "wb") + "/seller/" + item.s_id_BZ + "/info";
      return href;
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Детализация cписка ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      return [{
        title: "Категория",
        position: 1,
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, {
        title: "Впервые",
        position: 2,
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Продавец",
        position: 3,
        show: true,
        name: "seller",
        width: DataTable.ColWidth('seller'),
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                ...this.routeParams,
                s_id: item.s_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.seller,
            target: "_blank"
          };
        }
      }, {
        //&: 2024-11-29: INN/OGRN
        title: "ИНН продавца",
        name: "s_inn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "ОГРН продавца",
        name: "s_ogrn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продает на OZON и WB",
        name: "s_id_BZ",
        show: true,
        type: "slot",
        width: 120,
        filter: "numberRange"
      }];
    }
  }
};