var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main-page', [_c('main-title', [_vm._v(" Сравнение показателей и видимости карточек ")]), _c('main-description', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" В этом разделе вы можете сравнить свою карточку с конкурентами по различным метрикам продаж, "), _c('br'), _vm._v("а также сравнить видимость вашего товара в категориях и поисковой выдаче ")]), _c('div', {
    staticClass: "form mt32"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "30px"
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Выберите маркетплейс")]), _c('app-select', {
    staticClass: "select",
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.marketplace,
      callback: function ($$v) {
        _vm.marketplace = $$v;
      },
      expression: "marketplace"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Введите свой товар (SKU или ссылка с маркетплейса) ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product,
      expression: "product"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Например: 123456789",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.product
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.product = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "modal-item",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "modal-item__label",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._v(" Список конкурентов, до 10 шт. (SKU или ссылки с маркетплейса) "), _c('app-select', {
    staticClass: "select",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "items": _vm.enterTypeOptions
    },
    model: {
      value: _vm.enterType,
      callback: function ($$v) {
        _vm.enterType = $$v;
      },
      expression: "enterType"
    }
  }), _vm.enterType.id === 2 ? _c('div', {
    staticStyle: {
      "width": "100%",
      "max-width": "500px"
    }
  }, [_c('entity-search', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Название категории или ссылка (URL) категории",
      "no-data-text": "Категории не найдены",
      "text-prop": "c_name_path",
      "loader": _vm.searchLoader,
      "value": _vm.searchCategoryValue,
      "selectedMp": _vm.marketplace.id
    },
    on: {
      "select": _vm.onSelectSearchCategoryValue,
      "change-value": _vm.searchCategoryValueChange
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "margin-top": "20px"
    }
  }, [_c('lined-textarea', {
    staticClass: "input",
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": 10,
      "placeholder": "Введите список SKU или ссылок с маркетплейса, по одному в строке, например:\n58294056\n123975032\n682910567"
    },
    model: {
      value: _vm.competitors,
      callback: function ($$v) {
        _vm.competitors = $$v;
      },
      expression: "competitors"
    }
  })], 1)]), _c('button', {
    staticClass: "btn-blue",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.compare
    }
  }, [_vm._v(" Сравнить товары ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };