export default {
  props: {
    type: {
      type: String,
      default: "green"
    },
    text: {
      type: String,
      default: "Новинка"
    }
  }
};