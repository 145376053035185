var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e(), _c('switch-input', {
          attrs: {
            "label": "FBS"
          },
          model: {
            value: _vm.fbs,
            callback: function ($$v) {
              _vm.fbs = $$v;
            },
            expression: "fbs"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" Сравнение показателей товаров ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('fade-transition', [_vm.tabs_tables_model.id === 'dynamic' ? _c('app-select', {
    attrs: {
      "items": _vm.select_chart_items
    },
    model: {
      value: _vm.select_chart_model,
      callback: function ($$v) {
        _vm.select_chart_model = $$v;
      },
      expression: "select_chart_model"
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings ? _c('compare-products-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs_tables_model.id === 'table',
      expression: "tabs_tables_model.id === 'table'"
    }],
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings
    },
    on: {
      "updateDynamic": _vm.updateDynamic,
      "limitReached": _vm.limitReached
    }
  }) : _vm._e(), _vm.tabs_tables_model.id === 'dynamic' ? _c('div', [_c('large-chart', {
    attrs: {
      "datasets": _vm.chartDatasets,
      "period": _vm.period,
      "oneScale": ""
    }
  }), _c('div', {
    staticClass: "chart-legend"
  }, _vm._l(_vm.chartDatasets, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "chart-legend-item"
    }, [_c('div', {
      staticClass: "chart-legend-color",
      style: `background-color: ${item.colorHEX}`
    }), _c('div', {
      staticClass: "chart-legend-item-image"
    }, [_c('img', {
      attrs: {
        "src": item.image
      },
      on: {
        "error": function ($event) {
          $event.target.src = require('@/assets/images/img.svg');
        }
      }
    })]), _c('a', {
      staticClass: "link",
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.sku) + " ")])]);
  }), 0)], 1) : _vm._e()], 1), _c('main-title', {
    staticClass: "mt32"
  }, [_vm._v(" Сравнение позиций в категориях ")]), _c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId() + 1
    }
  })], 1), _vm.reportSettings ? _c('compare-categories-table', {
    attrs: {
      "id": _vm.getTableId() + 1,
      "report-settings": _vm.reportSettings
    },
    on: {
      "limitReached": _vm.limitReached
    }
  }) : _vm._e()], 1), _c('main-title', {
    staticClass: "mt32"
  }, [_vm._v(" Сравнение позиций в поисковой выдаче ")]), _c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId() + 2
    }
  })], 1), _vm.reportSettings ? _c('compare-keywords-table', {
    attrs: {
      "id": _vm.getTableId() + 2,
      "report-settings": _vm.reportSettings
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };