import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    NA: {
      type: String,
      default: "н/д"
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("category/getCategorySellersOld", {
        ...query,
        ...this.reportSettings
      });
    },
    href_s_id_BZ(item) {
      let href = "/" + (this.reportSettings.mp == "wb" ? "ozon" : "wb") + "/seller/" + item.s_id_BZ + "/info";
      return href;
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "Продавец",
        show: true,
        name: "seller",
        width: DataTable.ColWidth('seller'),
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                s_id: item.s_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.seller,
            target: "_blank"
          };
        }
      }, {
        //&: 2024-11-29: INN/OGRN
        title: "ИНН продавца",
        name: "s_inn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "ОГРН продавца",
        name: "s_ogrn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продает на OZON и WB",
        name: "s_id_BZ",
        show: true,
        type: "slot",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров с продажами",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Рейтинг товаров",
        show: true,
        name: "rating",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Отзывы",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Сред. отзывов на товар",
        show: true,
        name: "avg_reviews",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};