import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import ReportMixin from "@/mixins/Report";
import RangeCalendar from "@/components/RangeCalendar.vue";
import ModalListImport from "@/components/modals/ModalListImport.vue";
import ModalListRemoveItem from "@/components/modals/ModalListRemoveItem.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import MainTitle from "@/components/MainTitle.vue";
import CategoriesTable from "@/components/List/CategoriesTable.vue";
import ProductsTable from "@/components/List/ProductsTable.vue";
import BrandsTable from "@/components/List/BrandsTable.vue";
import SellersTable from "@/components/List/SellersTable.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
const tableComponents = {
  category: CategoriesTable,
  product: ProductsTable,
  brand: BrandsTable,
  seller: SellersTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$list;
    const name = (_this$list = this.list) === null || _this$list === void 0 ? void 0 : _this$list.title;
    return {
      title: name ? `Список "${name}"` : undefined
    };
  },
  data() {
    return {
      list: null,
      showModalEditLists: false,
      cells: null,
      force7RenderChart: 0
    };
  },
  async mounted() {
    await this.loadList();
  },
  methods: {
    loadOverview() {
      var _this$list2, _this$list3, _this$list4, _this$list5;
      console.log('131: this.list=', this.list);
      console.log('132: this.reportSettings=', this.reportSettings);
      if (((_this$list2 = this.list) === null || _this$list2 === void 0 ? void 0 : _this$list2.entity) == "category") {
        return this.$store.dispatch("list/ListCategoryOverview", this.reportSettings);
      }
      if (((_this$list3 = this.list) === null || _this$list3 === void 0 ? void 0 : _this$list3.entity) == "product") {
        return this.$store.dispatch("list/ListProductOverview", this.reportSettings);
      }
      if (((_this$list4 = this.list) === null || _this$list4 === void 0 ? void 0 : _this$list4.entity) == "brand") {
        return this.$store.dispatch("list/ListBrandOverview", this.reportSettings);
      }
      if (((_this$list5 = this.list) === null || _this$list5 === void 0 ? void 0 : _this$list5.entity) == "seller") {
        return this.$store.dispatch("list/ListSellerOverview", this.reportSettings);
      }
    },
    // override mixin marketplace getter
    getMP() {
      var _this$list6;
      return (_this$list6 = this.list) === null || _this$list6 === void 0 ? void 0 : _this$list6.mp;
    },
    getTableId() {
      return this._uid;
    },
    async loadList() {
      var _this$list7, _this$list8, _this$list9, _this$list10;
      this.list = await this.$store.dispatch("list/getList", {
        id: this.$route.params.id
      });
      console.log('159: this.list=', this.list);
      if (((_this$list7 = this.list) === null || _this$list7 === void 0 ? void 0 : _this$list7.entity) == "category") {
        let cells = getEntityCellsConfig("category");
        const customCells = {
          avgPerDay: {
            title: "Среднее<br>продаж в день",
            multi: [{
              id: "avg_sold",
              unit: cells.avg_sold.unit
            }, {
              id: "avg_revenue",
              unit: cells.avg_revenue.unit
            }],
            color: cells.avg_sold.color
          },
          //&:
          losses: {
            title: `Упущенная выручка`,
            color: "pink",
            multi: [{
              id: "losses",
              unit: "руб"
            }]
          }
        };
        delete cells.avg_sold;
        delete cells.avg_revenue;
        this.cells = {
          ...cells,
          ...customCells
        };
      }
      if (((_this$list8 = this.list) === null || _this$list8 === void 0 ? void 0 : _this$list8.entity) == "product") {
        let cells = getEntityCellsConfig("product");
        const customCells = {
          avgPerDay: {
            title: "Среднее<br>продаж в день",
            multi: [{
              id: "avg_sold",
              unit: cells.avg_sold.unit
            }, {
              id: "avg_revenue",
              unit: cells.avg_revenue.unit
            }],
            color: cells.avg_sold.color
          }
        };
        delete cells.avg_sold;
        delete cells.avg_revenue;
        delete cells.price;
        delete cells.price_old;
        delete cells.discount;
        delete cells.price_spp;
        delete cells.price_discount_spp;
        delete cells.discount_wb;
        this.cells = {
          ...cells,
          ...customCells
        };
      }
      if (((_this$list9 = this.list) === null || _this$list9 === void 0 ? void 0 : _this$list9.entity) == "brand") {
        let cells = getEntityCellsConfig("brand");
        const customCells = {
          avgPerDay: {
            title: "Среднее<br>продаж в день",
            multi: [{
              id: "avg_sold",
              unit: cells.avg_sold.unit
            }, {
              id: "avg_revenue",
              unit: cells.avg_revenue.unit
            }],
            color: cells.avg_sold.color
          }
        };
        delete cells.avg_sold;
        delete cells.avg_revenue;
        this.cells = {
          ...cells,
          ...customCells
        };
      }
      if (((_this$list10 = this.list) === null || _this$list10 === void 0 ? void 0 : _this$list10.entity) == "seller") {
        let cells = getEntityCellsConfig("seller");
        const customCells = {
          avgPerDay: {
            title: "Среднее<br>продаж в день",
            multi: [{
              id: "avg_sold",
              unit: cells.avg_sold.unit
            }, {
              id: "avg_revenue",
              unit: cells.avg_revenue.unit
            }],
            color: cells.avg_sold.color
          }
        };
        delete cells.avg_sold;
        delete cells.avg_revenue;
        this.cells = {
          ...cells,
          ...customCells
        };
      }
    },
    getTableComponent() {
      var _this$list11;
      return tableComponents[(_this$list11 = this.list) === null || _this$list11 === void 0 ? void 0 : _this$list11.entity];
    },
    deselect() {
      this.table_data.data.forEach(el => {
        el.checked = false;
      });
      this.select_all = false;
    },
    deleteItem() {
      let data = [];
      this.table_data.data.forEach(item => {
        if (!item.checked) {
          data.push(item);
        }
      });
      this.table_data.data = [...data];
    },
    onImportClick() {
      this.$refs.modalListImport.open({
        item: this.list
      });
    },
    onImportComplete() {
      this.$refs.table.refresh();
      this.force7RenderChart++;
      //console.log('254: force7RenderChart=',this.force7RenderChart)
    },
    on7RemoveItemComplete() {
      //this.$refs.table.refresh();

      this.force7RenderChart++;
      //console.log('261: force7RenderChart=',this.force7RenderChart)
    },
    onRemove({
      items,
      clean,
      refresh
    }) {
      this.$refs.modalListRemoveItem.open({
        list: this.list,
        items,
        onComplete() {
          clean();
          refresh();
        }
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Список ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    selectedItems() {
      if (this.table_data && this.table_data.data) {
        let res = [];
        this.table_data.data.forEach(el => {
          if (el.checked) res.push(el);
        });
        return res;
      } else {
        return [];
      }
    },
    listKey() {
      return `${this.$route.params.id}`;
    },
    reportSettings() {
      if (!this.calendar || !this.list) {
        return null;
      }
      console.log('317: this.route.query=', this.$route.query);
      return {
        id: this.$route.params.id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        ...this.$route.query
      };
    },
    routeParams() {
      var _this$list12;
      return {
        mp: (_this$list12 = this.list) === null || _this$list12 === void 0 ? void 0 : _this$list12.mp
      };
    }
  },
  watch: {
    listKey() {
      this.loadList();
    }
  },
  components: {
    RangeCalendar,
    ModalListImport,
    DataTableActions,
    MainTitle,
    ModalListRemoveItem,
    SwitchInput,
    EntityCharts
  }
};