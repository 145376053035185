var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list ? _c('div', {
    staticClass: "list-page"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e(), _c('switch-input', {
          attrs: {
            "label": "FBS"
          },
          model: {
            value: _vm.fbs,
            callback: function ($$v) {
              _vm.fbs = $$v;
            },
            expression: "fbs"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 4141761386)
  }, [_c('div', {
    staticClass: "list-page__title-block"
  }, [_c('router-link', {
    staticClass: "list-page__title-link",
    attrs: {
      "to": {
        name: 'Lists'
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "19",
      "height": "16",
      "viewBox": "0 0 19 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z",
      "fill": "#316D92",
      "fill-opacity": "0.9"
    }
  })])]), _c('span', [_vm._v(" " + _vm._s(_vm.list.title) + " ")])], 1)]), _vm.reportSettings && _vm.cells ? _c('entity-charts', {
    key: _vm.force7RenderChart,
    ref: "chart",
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.calendar
    }
  }) : _vm._e(), _c('div', {
    staticClass: "list-page__title mt32"
  }, [_c('div', {
    staticClass: "list-page__title-block"
  }, [_c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": _vm.onImportClick
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`),
      "width": "11",
      "height": "12"
    }
  }), _vm._v(" Добавить в список ")])]), _c('div', {
    staticClass: "list-page__title-buttons"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1)]), _c('div', {
    staticClass: "list-page__table mt32"
  }, [_vm.reportSettings ? _c(_vm.getTableComponent(), {
    key: `table:${_vm.listKey}`,
    ref: "table",
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "route-params": _vm.routeParams,
      "file-name": _vm.fileName,
      "mp": _vm.list.mp
    },
    on: {
      "selectAction": _vm.onRemove
    }
  }) : _vm._e()], 1), _c('modal-list-import', {
    ref: "modalListImport",
    on: {
      "complete": _vm.onImportComplete
    }
  }), _c('modal-list-remove-item', {
    ref: "modalListRemoveItem",
    on: {
      "complete": _vm.on7RemoveItemComplete
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };