import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import MainTitle from "@/components/MainTitle.vue";
import MainDescription from "@/components/MainDescription.vue";
import MainPage from "@/components/MainPage";
import AppSelect from "@/components/AppSelect.vue";
import LinedTextarea from "@/components/LinedTextarea.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import { getMarketplacesListArray } from "@/utils/enums";
export default {
  name: "CardComparison",
  components: {
    MainPage,
    MainTitle,
    MainDescription,
    AppSelect,
    LinedTextarea,
    EntitySearch
  },
  data() {
    const select_marketplace_items = [...getMarketplacesListArray()];
    const enterTypeOptions = [{
      id: 1,
      title: "Ручной ввод"
    }, {
      id: 2,
      title: "Авто подбор"
    }];
    return {
      textareaStyles: {
        height: "300px",
        width: "100%",
        resize: "vertical"
      },
      select_marketplace_items,
      marketplace: select_marketplace_items[0],
      product: null,
      competitors: "",
      enterTypeOptions,
      enterType: enterTypeOptions[0],
      // categoriesOptions: [],
      searchCategoryValue: "",
      category: null
    };
  },
  methods: {
    compare() {
      if (this.notRegister) {
        this.openRegisterModal();
      } else {
        this.$router.push({
          name: "CardComparisonResults",
          params: {
            mp: this.marketplace.id
          },
          query: {
            product: this.product,
            competitors: this.competitors
          }
        });
      }
    },
    openRegisterModal() {
      this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
        message: "Данный функционал доступен только для зарегистрированных пользователей"
      });
    },
    // async loadCategories() {
    //     let data = await this.$store.dispatch("category/getCategoryList", {
    //         c_id: 0,
    //         mp: this.marketplace.id,
    //     });
    //     this.categoriesOptions = data.map((item) => ({
    //         id: item.c_id,
    //         title: item.c_name,
    //     }));
    //     this.category = this.categoriesOptions[0];
    // },
    async searchLoader(query) {
      const result = await this.$store.dispatch("category/getCategoryByName", {
        query,
        mp: this.marketplace.id
      });
      return result;
    },
    searchCategoryValueChange(value) {
      this.searchCategoryValue = value;
    },
    onSelectSearchCategoryValue(value) {
      this.category = value;
      this.searchCategoryValue = value.c_name;
    },
    async getCompareTopSku() {
      let {
        data
      } = await this.$store.dispatch("product/getCompareTopSku", {
        mp: this.marketplace.id,
        category: this.category.c_id
      });
      this.competitors = data.join("\n");
    }
  },
  watch: {
    marketplace() {
      this.searchQueriesValue = "";
    },
    // marketplace: {
    //     handler(newValue, oldValue) {
    //         if (newValue.id !== oldValue?.id) {
    //             // this.loadCategories();
    //             this.searchQueriesValue = "";
    //         }
    //     },
    //     immediate: true,
    // },
    category() {
      if (this.enterType.id === 2) {
        this.getCompareTopSku();
      }
    },
    // enterType(val) {
    //     if (val.id === 2) {
    //         this.getCompareTopSku();
    //     }
    // },
    $route: {
      handler() {
        if (this.notRegister) {
          this.openRegisterModal();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    valid() {
      var _this$product, _this$competitors;
      return ((_this$product = this.product) === null || _this$product === void 0 ? void 0 : _this$product.length) && ((_this$competitors = this.competitors) === null || _this$competitors === void 0 ? void 0 : _this$competitors.length);
    },
    currentSubscriptions() {
      return this.$access.getSubscriptions();
    },
    user() {
      return this.$store.state.user.user;
    },
    notRegister() {
      return !this.user && !this.currentSubscriptions.length;
    },
    hasFreePlan() {
      return this.user && !this.currentSubscriptions.length;
    },
    hasStartPlan() {
      return this.currentSubscriptions.length && this.currentSubscriptions.every(sub => sub.plan.name === "Start");
    }
  }
};