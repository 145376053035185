var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.noTabs,
      expression: "!noTabs"
    }],
    staticClass: "cat-info__tabs"
  }, [_c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_charts
    },
    model: {
      value: _vm.tabs_charts_active,
      callback: function ($$v) {
        _vm.tabs_charts_active = $$v;
      },
      expression: "tabs_charts_active"
    }
  }, [_c('fade-transition', [_vm.tabs_charts_active == _vm.tabs_charts[1] ? _c('app-select-multi', {
    attrs: {
      "items": _vm.select_chart_items
    },
    model: {
      value: _vm.select_chart_model,
      callback: function ($$v) {
        _vm.select_chart_model = $$v;
      },
      expression: "select_chart_model"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "content"
  }, [_vm.loading || _vm.forceLoading ? _c('loading-overlay') : _vm._e(), !_vm.loading && _vm.error ? _c('loading-overlay', [_vm._v(" Нет данных ")]) : _vm._e(), _vm.showFirstTab ? _c('cell-list', [_vm._l(_vm.cells, function (cell, key) {
    return [cell.multi ? _c('cell-item-multi', {
      key: key,
      attrs: {
        "items": _vm.getMulti(key),
        "color": cell.color,
        "title": cell.title
      }
    }) : _vm.qSkipCellIfNotLoaded === false || _vm.qSkipCellIfNotLoaded == 1 && (!_vm.loaded || _vm.loaded[key] && _vm.getLoaded(key).total != 0) ? _c('cell-item', {
      key: key,
      staticClass: "cell-clickable",
      attrs: {
        "title": cell.title,
        "info": _vm.getLoaded(key).total,
        "infoMin": _vm.getSign(key),
        "color": cell.color,
        "chart": _vm.getLoaded(key).dynamic
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onCellClick(key);
        }
      }
    }) : _vm._e()];
  })], 2) : _vm._e(), _c('div', {
    staticClass: "cat-info__chart"
  }, [_vm.tabs_charts_active == _vm.tabs_charts[1] ? _c('large-chart', {
    attrs: {
      "datasets": _vm.chartDatasets,
      "period": _vm.period
    }
  }) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };