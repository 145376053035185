var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "datatable"
  }, [_vm.id && _vm.configurable ? _c('portal', {
    attrs: {
      "to": `__dt-actions-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "actions"
  }, [!_vm.qSkip_ViewButton ? _c('button', {
    staticClass: "btn-outline btn-width-auto actions__item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.show_table_settings_columns = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/settings.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Вид "), _vm.settingsChanged ? _c('div', {
    staticClass: "indicator"
  }) : _vm._e()]) : _vm._e(), !_vm.qSkip_FilterButton ? _c('button', {
    staticClass: "btn-outline btn-width-auto actions__item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.show_table_settings_filters = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/filters.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Фильтры "), _vm.filtersChanged ? _c('div', {
    staticClass: "indicator"
  }) : _vm._e()]) : _vm._e(), _vm.tableId === 'DeliveryCalculation' && _vm.loaderConfig.marketplace === 'wb' ? _c('button', {
    staticClass: "btn-outline btn-width-auto actions__item",
    attrs: {
      "disabled": _vm.exporting
    },
    on: {
      "click": function ($event) {
        return _vm.onExport();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/export.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Экспорт WB ")]) : _vm.tableId === 'DeliveryCalculation' && _vm.loaderConfig.marketplace === 'ozon' ? _c('button', {
    staticClass: "btn-outline btn-width-auto actions__item",
    attrs: {
      "disabled": _vm.exporting
    },
    on: {
      "click": function ($event) {
        return _vm.onExport();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/export.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Экспорт OZON ")]) : !_vm.qSkip_ExportButton ? _c('button', {
    staticClass: "btn-outline btn-width-auto actions__item",
    attrs: {
      "disabled": _vm.exporting
    },
    on: {
      "click": function ($event) {
        return _vm.onExport();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/export.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Экспорт ")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "table-content"
  }, [_c('data-table-table', {
    attrs: {
      "columns": _vm.columnsExtended,
      "items": _vm.loadedItems,
      "sort": _vm.sort,
      "selected": _vm.selectedItems,
      "loading": _vm.loading,
      "error": _vm.error,
      "raw-response": _vm.rawResponse,
      "item-id": _vm.itemId,
      "selectable": _vm.selectable,
      "addable": _vm.addable,
      "sticky-columns": _vm.stickyColumns,
      "rowClickable": _vm.rowClickable,
      "no-data": _vm.noData,
      "forceLoading": _vm.forceLoading
    },
    on: {
      "update:sort": function ($event) {
        _vm.sort = $event;
      },
      "update:selected": function ($event) {
        _vm.selectedItems = $event;
      },
      "refresh": function ($event) {
        _vm.refreshKey++;
      },
      "rowClick": item => _vm.$emit('rowClick', item),
      "addNew": function ($event) {
        return _vm.$emit('addNew');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function (scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    }), _vm.paginatable ? {
      key: "footer",
      fn: function () {
        return [_c('pagination', {
          attrs: {
            "total": _vm.total,
            "count": _vm.count
          },
          model: {
            value: _vm.pagination,
            callback: function ($$v) {
              _vm.pagination = $$v;
            },
            expression: "pagination"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }), _c('table-settings-columns', {
    attrs: {
      "show": _vm.show_table_settings_columns,
      "columns": _vm.columns,
      "model-value": _vm.columnSettings
    },
    on: {
      "close": function ($event) {
        _vm.show_table_settings_columns = false;
      },
      "change": function ($event) {
        return _vm.onSettingsChange($event);
      }
    }
  }), _c('table-settings-filters', {
    attrs: {
      "show": _vm.show_table_settings_filters,
      "available": _vm.filtersAvailable,
      "table-id": _vm.tableId,
      "ranges": _vm.ranges
    },
    on: {
      "close": function ($event) {
        _vm.show_table_settings_filters = false;
      }
    },
    model: {
      value: _vm.filters,
      callback: function ($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _vm.selectedItems.length > 0 ? _c('selected-table-items', {
    attrs: {
      "items": _vm.selectedItems,
      "action": _vm.selectAction
    },
    on: {
      "update:items": function ($event) {
        _vm.selectedItems = $event;
      },
      "action": _vm.onSelectAction,
      "actionM": _vm.onSelectActionM,
      "action_rrcPriceFresh": _vm.onSelectAction_RrcPriceFresh,
      "action1": _vm.onSelectAction1,
      "action2": _vm.onSelectAction2,
      "action3": _vm.onSelectAction3,
      "action4": _vm.onSelectAction4
    }
  }) : _vm._e(), _c('modal-list-add-items', {
    ref: "modalListAddItems"
  }), _c('modal-list-m-add-items', {
    ref: "modalListMAddItems"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };