import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import ReportMixin from "@/mixins/Report";
import AppTabs from "@/components/AppTabs.vue";
import AppSelect from "@/components/AppSelect.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import CompareProductsTable from "@/components/CardComparison/CompareProductsTable.vue";
import CompareCategoriesTable from "@/components/CardComparison/CompareCategoriesTable.vue";
import CompareKeywordsTable from "@/components/CardComparison/CompareKeywordsTable.vue";
import LargeChart from "@/components/charts/LargeChart.vue";
import { dateRangeDaysArray, FORMAT } from "@/utils/dates";
import colors from "@/utils/colors";
const COLORS = ["red", "green", "blue", "lightgreen", "brown", "yellow", "pink", "lightyellow", "orange", "fiolet", "darkblue"];
export default {
  name: "CardComparisonResults",
  components: {
    ReportPage,
    MainTitle,
    AppTabs,
    AppSelect,
    DataTableActions,
    SwitchInput,
    CompareProductsTable,
    CompareCategoriesTable,
    CompareKeywordsTable,
    LargeChart
  },
  mixins: [ReportMixin],
  data() {
    const tabs_tables = [{
      id: "table",
      title: "Сводка за период"
    }, {
      id: "dynamic",
      title: "Динамика показателей"
    }];
    const select_chart_items = [{
      id: "categories",
      title: "Категории",
      value: "categories"
    }, {
      id: "discount",
      title: "Скидка",
      value: "discount"
    }, {
      id: "keywords",
      title: "Поисковые запросы",
      value: "keywords"
    }, {
      id: "price",
      title: "Цена",
      value: "price"
    }, {
      id: "price_old",
      title: "Старая цена",
      value: "price_old"
    }, {
      id: "rating",
      title: "Рейтинг",
      value: "rating"
    }, {
      id: "remains",
      title: "Текущий остаток",
      value: "remains"
    }, {
      id: "revenue",
      title: "Выручка",
      value: "revenue"
    }, {
      id: "reviews",
      title: "Отзывы",
      value: "reviews"
    }, {
      id: "sold",
      title: "Продажи (шт)",
      value: "sold"
    }];
    return {
      tabs_tables,
      tabs_tables_model: tabs_tables[0],
      chartRefresh: 0,
      datasets: [],
      select_chart_items,
      select_chart_model: select_chart_items[0],
      limitError: false
    };
  },
  methods: {
    getTableId() {
      return this._uid;
    },
    updateDynamic(data) {
      this.datasets = [...data];
    },
    limitReached(message) {
      this.limitError = true;
      this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedTemplate"), {
        alert: "Превышен лимит на вашем тарифном плане",
        title: message
      });
    }
  },
  computed: {
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const reportSettings = {
        mp: this.$route.params.mp,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        sku: [this.$route.query.product, ...this.$route.query.competitors.split("\n")]
      };
      return reportSettings;
    },
    period() {
      if (!this.reportSettings) {
        return [];
      }
      const range = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2, FORMAT);
      return range;
    },
    chartDatasets() {
      return this.datasets.map((item, index) => {
        return {
          color: COLORS[index],
          colorHEX: colors[COLORS[index]].color,
          data: item.dynamic[this.select_chart_model.id],
          unit: item === null || item === void 0 ? void 0 : item.unit,
          title: item.title,
          sku: item.p_id,
          link: item.mp_url,
          image: item.image
        };
      });
    }
  }
};