import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import LinedTextarea from "@/components/LinedTextarea.vue";
import ProductsTable from "@/components/Repricer/ProductsTable.vue";
import ImportBtn from "@/components/Seo/ImportBtn.vue";
import AppSelect from "@/components/AppSelect.vue";
import Checkbox from "@/components/Checkbox.vue";
import RepricerMixin from "@/mixins/Repricer";
import HasTokens from "@/components/HasTokensRepricer.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import ModalSaveConfirm from "@/components/modals/ModalRepricerScriptSaveConfirm.vue";
import ModalDeleteConfirm from "@/components/modals/ModalRepricerScriptDeleteConfirm.vue";
import ModalUpdatePrice from "@/components/modals/ModalUpdateRepricerPrice.vue";
import Tooltip from "@/components/Tooltip.vue";
import { read, utils } from "xlsx";
import hash from "object-hash";
import NewFeature from "@/components/NewFeature.vue";
export default {
  mixins: [RepricerMixin],
  meta: {
    title: "Создание сценария"
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    WarningRepricer,
    DataLoading,
    LinedTextarea,
    ProductsTable,
    ImportBtn,
    Checkbox,
    AppSelect,
    ModalSaveConfirm,
    ModalDeleteConfirm,
    ModalUpdatePrice,
    Tooltip,
    NewFeature
  },
  data() {
    return {
      token: null,
      name: null,
      oldName: null,
      track_promotions_off: false,
      type: null,
      products: [],
      skuList: "",
      skuList2: "",
      availableItems: 1000,
      textareaStyles: {
        height: "300px",
        width: "100%",
        resize: "vertical"
      },
      scriptTypes: [],
      productsToRemove: [],
      productsToUpdate: [],
      productsToAdd: [],
      remainder: {
        status: false,
        quantity: "",
        percent: ""
      },
      errors: [],
      usedSku: [],
      walletDiscount: false,
      query: {
        page: 1,
        page_size: 20,
        select: ["items"]
      },
      productsTotal: 0,
      productsSuccess: false,
      productsMessage: "",
      iterationsOptions: Array.from({
        length: 24
      }, (_, index) => ({
        id: index + 1,
        title: (index + 1).toString()
      })),
      iteration: 1,
      timePickerOptions: Array.from({
        length: 24
      }, (_, index) => {
        const time = index.toString().padStart(2, "0") + ":00";
        return {
          id: time,
          title: time
        };
      }),
      defaultSceduleAction: {}
    };
  },
  async created() {
    await this.getScriptTypes();
    if (this.$route.params.id) {
      await this.loadScript();
      await this.loadProducts();
    }
  },
  methods: {
    updateQuery(val) {
      this.query = val;
    },
    downloadList(e) {
      e.preventDefault();
      let string = "";
      this.usedSku.forEach(item => {
        string += item + "\n";
      });
      const url = window.URL.createObjectURL(new Blob([string]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Товары, уже использовавшиеся в другом сценарии или аккаунте`;
      link.setAttribute("download", `${fileName}.txt`); //&:

      document.body.appendChild(link);
      link.click();
    },
    async removeProducts({
      items,
      clean
    }) {
      this.$refs.modalDeleteConfirm.open({
        onComplete: async () => {
          const productsToRemove = items.filter(item => !item.new).map(item => item.id);
          const selectedProducts = items.map(item => item.id);
          this.products = this.products.filter(item => !selectedProducts.includes(item.id));
          this.productsToAdd = this.productsToAdd.filter(item => !selectedProducts.includes(item.id));
          if (this.$route.params.id) {
            if (productsToRemove.length) {
              await this.$store.dispatch("repricer/removeProducts", productsToRemove);
            }
          }
          clean();
        }
      });
    },
    addItem() {
      const newProduct = {
        id: this.products.length + 1,
        sku: "",
        new: true,
        min_price: "",
        max_price: ""
      };
      if (this.type === "compensation") {
        newProduct.target_price = "";
      }
      this.productsToAdd = [...this.productsToAdd, newProduct];
      this.products = [...this.products, newProduct];
    },
    updateMinPrice({
      items,
      clean
    }) {
      const selectedProducts = items.map(item => item.id);
      const productsToUpdate = [];
      this.$refs.modalUpdatePrice.open({
        onComplete: async price => {
          this.products.forEach(item => {
            if (selectedProducts.includes(item.id)) {
              item.min_price = price;
              if (!item.new) {
                productsToUpdate.push({
                  ...item
                });
              }
            }
          });
          await this.updateProducts(productsToUpdate);
          clean();
        }
      });
    },
    updateMaxPrice({
      items,
      clean
    }) {
      const selectedProducts = items.map(item => item.sku);
      const productsToUpdate = [];
      this.$refs.modalUpdatePrice.open({
        onComplete: async price => {
          this.products.forEach(item => {
            if (selectedProducts.includes(item.sku)) {
              item.max_price = price;
              if (!item.new) {
                productsToUpdate.push({
                  ...item
                });
              }
            }
          });
          await this.updateProducts(productsToUpdate);
          clean();
        }
      });
    },
    updateExpectedPrice({
      items,
      clean
    }) {
      const selectedProducts = items.map(item => item.sku);
      const productsToUpdate = [];
      this.$refs.modalUpdatePrice.open({
        onComplete: async price => {
          this.products.forEach(item => {
            if (selectedProducts.includes(item.sku)) {
              item.target_price = price;
              if (!item.new) {
                productsToUpdate.push({
                  ...item
                });
              }
            }
          });
          await this.updateProducts(productsToUpdate);
          clean();
        }
      });
    },
    async updateProducts(products) {
      if (products.length) {
        return await this.$store.dispatch("repricer/updateProducts", products.map(item => {
          const nm_id = item.sku;
          delete item.sku;
          const result = {
            id: item.id,
            nm_id,
            min_price: item.min_price,
            max_price: item.max_price,
            active: item.active,
            repricer_script_id: this.$route.params.id
          };
          if (this.type === "compensation") {
            result.target_price = item.target_price;
          }
          return result;
        }));
      }
    },
    skuList2Import(list) {
      list.forEach(row => {
        this.skuList2 += row + "\n";
      });
    },
    handleFileSelect(event) {
      const files = event.target.files;
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheetName => {
          let XL_row_object = utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: "A"
          });
          const newProducts = XL_row_object.map((item, index) => {
            const result = {
              id: index + Math.random(),
              sku: item.A || "",
              new: true
            };
            if (this.type === "compensation") {
              result.target_price = item.B || "";
            } else {
              result.min_price = item.B || "";
              result.max_price = item.C || "";
            }
            return result;
          }).filter((item, index) => index > 0);
          this.productsToAdd = [...this.productsToAdd, ...newProducts];
          this.products = [...this.products, ...newProducts];
        });
      };
      reader.onerror = function (ex) {
        console.error(ex);
      };
      reader.readAsBinaryString(files[0]);
      this.$refs.file.value = "";
    },
    async getScriptTypes() {
      try {
        var _data;
        let {
          data
        } = await this.$store.dispatch("repricer/getScriptTypes");
        data = data.map(item => {
          var _condition$unit;
          let action = [];
          const condition = JSON.parse(item.condition);
          const expression = JSON.parse(item.expression);

          // Преобразование для корректной отрисовки условий
          if (condition) {
            if (!condition.operation && condition.title) {
              condition.operation = ["default"];
              condition.unit = ["default"];
              condition.title = {
                default: condition.title
              };
            }
            condition.operation.forEach((operation, index) => {
              // const defaultUnit = condition.unit[0];
              let expressionOperation = "";
              // if (item.id === 4) {
              //     if (operation === "<") {
              //         expressionOperation = expression.operation[0];
              //     }
              //     if (operation === ">") {
              //         expressionOperation = expression.operation[1];
              //     }
              // } else {
              // }
              expressionOperation = expression.operation[index];
              const expressionOperationTitle = expression.title[expressionOperation];
              const titleOptions = Object.keys(expression.title).map(key => ({
                id: key,
                title: expression.title[key].replace(/{[^}]*}/g, "")
              }));
              action.push({
                selected: item.slug === "timing",
                condition: {
                  operation: operation,
                  title: condition.title[operation],
                  value: item.slug === "timing" ? {
                    id: "00:00",
                    title: "00:00"
                  } : "",
                  unit: {
                    id: condition.unit[0],
                    title: condition.unit[0]
                  }
                },
                expression: {
                  operation: expressionOperation,
                  title: expressionOperationTitle,
                  value: "",
                  unit: {
                    id: expression.unit[0],
                    title: expression.unit[0]
                  },
                  titleOptions: titleOptions
                }
              });
            });
          } else {
            if (expression) {
              expression.operation.forEach(operation => {
                const defaultUnit = expression.unit[0];
                const operationTitle = expression.title[operation];
                action.push({
                  selected: item.slug === "timing",
                  expression: {
                    operation: operation,
                    title: operationTitle,
                    value: "",
                    unit: {
                      id: defaultUnit,
                      title: defaultUnit
                    }
                  }
                });
              });
            }
          }
          return {
            ...item,
            radio: "",
            condition: {
              ...condition,
              unit: condition === null || condition === void 0 || (_condition$unit = condition.unit) === null || _condition$unit === void 0 ? void 0 : _condition$unit.map(unit => {
                return {
                  id: unit,
                  title: unit
                };
              })
            },
            expression: {
              ...expression,
              unit: expression === null || expression === void 0 ? void 0 : expression.unit.map(unit => {
                return {
                  id: unit,
                  title: unit
                };
              })
            },
            action
          };
        });
        this.defaultSceduleAction = JSON.parse(JSON.stringify((_data = data) === null || _data === void 0 || (_data = _data.find(item => item.slug === "timing")) === null || _data === void 0 ? void 0 : _data.action[0]));
        this.scriptTypes = data;
        this.type = data[0].slug;
      } catch (error) {
        console.error("Error in getScriptTypes:", error);
      }
    },
    async loadScript() {
      try {
        const data = await this.$store.dispatch("repricer/getScript", this.$route.params.id);
        const scriptValue = JSON.parse(data.script_value);
        this.name = data.name;
        this.oldName = data.name;
        this.track_promotions_off = !!data.track_promotions_off;
        this.remainder.status = !!data.track_remainder;
        this.remainder.quantity = data.remainder_quantity;
        this.remainder.percent = data.remainder_percent;
        this.walletDiscount = !!data.wallet_discount;
        this.type = this.scriptTypes.find(item => item.id === data.script_type_id).slug;
        this.token = this.select_tokens_items.find(item => item.id === data.user_key_id);
        if (this.type === "timing") {
          const iterations = scriptValue.length;
          this.iteration = {
            id: iterations,
            title: String(iterations)
          };
        }
        this.$nextTick(async () => {
          const currentType = this.scriptTypes.find(item => item.id === data.script_type_id);

          // Сопоставляем значения скприпта с бека с фронтовой структурой
          scriptValue === null || scriptValue === void 0 || scriptValue.forEach((script, scriptIndex) => {
            currentType.action.forEach((item, actionIndex) => {
              let bool = false;
              if (currentType.slug === "timing") {
                bool = scriptIndex === actionIndex;
              } else {
                var _script$condition, _script$expression2;
                if (!((_script$condition = script.condition) !== null && _script$condition !== void 0 && _script$condition.operation)) {
                  var _script$expression, _item$expression;
                  bool = ((_script$expression = script.expression) === null || _script$expression === void 0 ? void 0 : _script$expression.operation) === ((_item$expression = item.expression) === null || _item$expression === void 0 ? void 0 : _item$expression.operation);
                } else if (!((_script$expression2 = script.expression) !== null && _script$expression2 !== void 0 && _script$expression2.operation)) {
                  var _script$condition2, _item$condition;
                  bool = ((_script$condition2 = script.condition) === null || _script$condition2 === void 0 ? void 0 : _script$condition2.operation) === ((_item$condition = item.condition) === null || _item$condition === void 0 ? void 0 : _item$condition.operation);
                } else {
                  var _script$condition3, _item$condition2, _script$expression3, _item$expression2;
                  bool = ((_script$condition3 = script.condition) === null || _script$condition3 === void 0 ? void 0 : _script$condition3.operation) === ((_item$condition2 = item.condition) === null || _item$condition2 === void 0 ? void 0 : _item$condition2.operation) || ((_script$expression3 = script.expression) === null || _script$expression3 === void 0 ? void 0 : _script$expression3.operation) === ((_item$expression2 = item.expression) === null || _item$expression2 === void 0 ? void 0 : _item$expression2.operation);
                }
              }
              if (bool) {
                item.selected = true;
                const condition = script.condition;
                const expression = script.expression;
                item.condition = {
                  ...item.condition,
                  value: currentType.slug === "timing" ? {
                    id: condition.value,
                    title: condition.value
                  } : condition.value,
                  unit: {
                    id: condition.unit,
                    title: condition.unit
                  }
                };
                item.expression = {
                  ...item.expression,
                  value: expression.value,
                  unit: {
                    id: expression.unit,
                    title: expression.unit
                  }
                };
                if (currentType.slug === "timing") {
                  item.expression.operation = item.expression.titleOptions.find(item => item.id === script.expression.operation);
                }
              }
            });
          });
          if (this.type.startsWith("competitor")) {
            currentType.radio = scriptValue[0].expression.operation;
            const {
              data
            } = await this.$store.dispatch("repricer/getScriptCompetitors", {
              id: this.$route.params.id
            });
            this.skuList2Import(data.map(item => item.sku));
          }
        });
        return data;
      } catch (error) {
        console.error("Error in loadScript:", error);
      }
    },
    async loadProducts() {
      try {
        this.productsToAdd = [];
        const data = await this.$store.dispatch("repricer/loadProducts", {
          ...this.query,
          id: this.$route.params.id
        });
        this.products = data.data.map(item => {
          const result = {
            id: item.id,
            sku: item.sku,
            min_price: item.min_price,
            max_price: item.max_price,
            active: item.active,
            disabled: item.disabled,
            repricer_script_id: this.$route.params.id
          };
          if (this.type === "compensation") {
            result.target_price = item.target_price;
          }
          return result;
        });
        this.productsTotal = data.totals.total;
        this.productsSuccess = data.success;
        this.productsMessage = data.message;
      } catch (error) {
        console.error("Error in loadProducts:", error);
      }
    },
    async saveScript() {
      // Обратоное преобразование для отправки на бек
      const script_value = [];
      const selectedType = this.scriptTypes.find(item => this.type === item.slug);
      if (!this.type.startsWith("competitor")) {
        selectedType.action.forEach(item => {
          if (item.selected) {
            let condition = {};
            let expression = {};
            if (item.condition) {
              condition = {
                operation: item.condition.operation === "default" ? "" : item.condition.operation,
                value: item.condition.value.id ? item.condition.value.id : item.condition.value,
                unit: item.condition.unit.id === "default" ? "" : item.condition.unit.id
              };
            }
            expression = {
              operation: item.expression.operation.id ? item.expression.operation.id : item.expression.operation,
              value: item.expression.value,
              unit: item.expression.unit.id
            };
            script_value.push({
              condition,
              expression
            });
          }
        });
      } else {
        selectedType.action.forEach(item => {
          if (item.expression.operation === selectedType.radio) {
            let condition = {};
            let expression = {};
            if (item.condition) {
              condition = {
                operation: item.condition.operation,
                value: item.condition.value,
                unit: item.condition.unit.id
              };
            }
            expression = {
              operation: item.expression.operation,
              value: item.expression.value,
              unit: item.expression.unit.id
            };
            script_value.push({
              condition,
              expression
            });
          }
        });
      }
      const body = {
        name: this.name,
        track_promotions_off: this.track_promotions_off,
        user_key_id: this.token.id,
        script_type_id: this.scriptTypes.find(item => this.type === item.slug).id,
        sku_competitor_list: this.skuList2 ? this.skuList2.split("\n").filter(item => item != "") : [],
        script_value
      };
      body.track_remainder = this.remainder.status;
      if (this.remainder.status) {
        body.remainder_quantity = this.remainder.quantity;
        body.remainder_percent = this.remainder.percent;
      }
      if (this.type === "compensation" || this.type === "competitorSpp") {
        body.wallet_discount = this.walletDiscount;
      }
      if (this.productsToAdd.length) {
        body.sku_range_price_list = [...this.productsToAdd.map(item => {
          const newItem = JSON.parse(JSON.stringify(item));
          const sku = newItem.sku;
          delete newItem.sku;
          delete newItem.id;
          delete newItem.new;
          const result = {
            ...newItem,
            nm_id: sku
          };
          if (this.$route.params.id) {
            result.repricer_script_id = this.$route.params.id;
          }
          return result;
        })];
      }
      this.errors = [];
      this.usedSku = [];
      if (!this.$route.params.id) {
        try {
          await this.$store.dispatch("repricer/addScript", body);
          this.$router.push({
            name: "RepricerScripts"
          });
        } catch (e) {
          this.preformErrors(e);
        }
      } else {
        this.$refs.modalSaveConfirm.open({
          onComplete: async () => {
            try {
              await this.$store.dispatch("repricer/updateScript", {
                ...body,
                id: this.$route.params.id
              });
              this.$router.push({
                name: "RepricerScripts"
              });
            } catch (e) {
              this.preformErrors(e);
            }
          }
        });
      }
    },
    preformErrors(e) {
      const toastParams = {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      };
      const errors = e.response.data.errors;
      if (errors.sku_unique) {
        this.usedSku = errors.sku_unique;
      }
      Object.keys(errors).forEach(item => {
        if (item !== "sku_unique") {
          this.errors.push({
            name: item,
            text: errors[item].join(", ")
          });
        }
        errors[item].forEach(error => {
          this.$toast.error(error, toastParams);
        });
      });
    }
  },
  computed: {
    queryHash() {
      return hash(JSON.stringify(this.query));
    },
    saveEnabled() {
      return this.name && this.products.length && this.products.every(item => ((item.min_price || item.min_price === 0) && item.max_price || this.type === "compensation") && item.sku);
    },
    reportSettings() {
      return {
        type: this.type
      };
    }
  },
  watch: {
    async queryHash() {
      await this.loadProducts();
    },
    select_tokens_items(val) {
      this.token = val[0];
    },
    iteration(val) {
      const count = val.id;

      // Находим текущий тип сценария (Расписание)
      const currenctScriptType = this.scriptTypes.find(item => item.slug === this.type);

      // Удаляем все акшены кроме заполненных
      currenctScriptType.action = currenctScriptType.action.filter((item, index) => {
        return (item.condition.value.id !== "00:00" || item.expression.value) && index < count;
      });
      const prevActions = currenctScriptType.action.length;

      // Заполяем оставшиеся акшены по кол-ву итераций
      for (let i = prevActions; i < count; i++) {
        currenctScriptType.action.push(JSON.parse(JSON.stringify(this.defaultSceduleAction)));
      }
    }
  }
};