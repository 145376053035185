import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
//import { getEntityTableColumns } from "@/utils/enums";
//import { dateRangeDays } from "@/utils/dates";

export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    }
  },
  // created() {
  //     this.reportSettings.mp = this.routeParams.mp
  // },

  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("list/getListProducts", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      //console.log('this.reportSettings=', this.reportSettings)
      //console.log('this.routeParams=', this.routeParams)

      /*
      return [
          {
              title: "Товар",
              position: 1,
              show: true,
              name: "title",
              width: 380,
              filter: "text",
              type: "link",
              noOverflow: true,
              getImage: (item) => item.image,
              getLink: (item) => {
                  return {
                      route: {
                          name: "ProductInfo",
                          params: {
                              ...this.routeParams,
                              p_id: item.p_id,
                          },
                          query: {
                              date: this.reportSettings.date,
                              date2: this.reportSettings.date2,
                              fbs: this.reportSettings.fbs,
                          },
                      },
                      text: item.title,
                      target: "_blank",
                  };
              },
          },
          {
              title: "SKU",
              position: 2,
              show: true,
              name: "sku",
              width: 100,
              filter: "text",
              type: "link",
              getLink: (item) => {
                  return {
                      href: item.mp_url,
                      text: item.sku,
                  };
              },
          },
           ...getEntityTableColumns("product", 3),
          {
              title: "Впервые",
              show: true,
              name: "date",
              width: 100,
              filter: "dateRange",
              type: "date",
          },
          {
              title: "Последний раз",
              show: true,
              name: "date2",
              width: 110,
              filter: "dateRange",
              type: "date",
          },
      ];
      */

      // = /src/components/Category/ProductsTable.vue
      /*
      const days = dateRangeDays(
          this.reportSettings.date,
          this.reportSettings.date2
      );
      */

      let a = [{
        title: "Товар",
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        //title: `Продано за ${days} дн (шт)`,
        title: `Продажи (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        //title: `Выручка за ${days} дн`,
        title: `Выручка`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }];
      if (this.routeParams.mp == "wb") {
        a = a.concat([{
          //title: "Цена",
          title: "Цена с WB кошельком",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        },
        /*
        {
            title: "СПП",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "Базовая цена",
          title: "Цена",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount_wb",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        a = a.concat([{
          title: "Цена",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }

      /*
      {
          title: "Категория",
          show: true,
          name: "category",
          width: 380,
          filter: "select",
          type: "slot",
      },
      {
          title: "Позиция",
          show: true,
          name: "position",
          type: "number",
          width: 100,
          filter: "numberRange",
      },
      */

      a = a.concat([
      /*
      {
          title: `Отзывов за ${days} дней`,
          show: true,
          name: "new_reviews",
          type: "number",
          width: 100,
          filter: "numberRange",
      },
      */
      {
        //title: "Текущий остаток (шт)",
        title: "Текущий остаток",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Отзывы",
        show: true,
        name: "p_reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Рейтинг",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Кол-во категорий",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        //title: "Кол-во запросов",
        title: "Поисковые запросы",
        show: true,
        name: "keywords",
        width: 100,
        filter: "numberRange",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id,
                tab: "keywordsPositions"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keywords,
            target: "_blank"
          };
        }
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Дней в наличии`,
        show: true,
        name: "days",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Упущенная выручка`,
        show: true,
        name: "losses",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: "Впервые",
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Последний раз",
        show: true,
        name: "date2",
        width: 110,
        filter: "dateRange",
        type: "date"
      }]);

      /*
      {
          title: "Бренд",
          show: true,
          name: "brand",
          width: DataTable.ColWidth("brand"),
          filter: "select",
          type: "link",
          getLink: (item) => {
              return {
                  route: {
                      name: "BrandInfo",
                      params: {
                          b_id: item.b_id,
                      },
                      query: {
                          date: this.reportSettings.date,
                          date2: this.reportSettings.date2,
                          fbs: this.reportSettings.fbs,
                      },
                  },
                  text: item.brand,
                  target: "_blank",
              };
          },
      },
      ];
      */

      /*
      a = a.concat([
      {title: "Вес, г",
          name: "p_weight",
          type: "number",
          width: 100,
          filter: "numberRange",
          show: true,
      }])
      if( this.reportSettings.mp == "ozon" ){
          a = a.concat([
          {title: "Цена продавца",
              name: "p_true_seller_price",
              type: "money",
              width: 100,
              filter: "numberRange",
              show: true,
          },
          {title: "Процент выкупа, %",
              name: "p_delivered_percent",
              type: "percent",
              width: 100,
              filter: "numberRange",
              show: true,
          }])
      }
      */

      /*
      {
          title: "Склад",
          show: true,
          name: "warehouse",
          width: 120,
          filter: "select",
      },
      */
      /*
      if (this.reportSettings.mp == "wb")
          a = a.concat([
              {
                  title: "Кол-во картинок",
                  show: true,
                  name: "tot_photo",
                  type: "number",
                  width: 100,
                  filter: "numberRange",
              },
          ]);
      */

      /*
      a = a.concat([
          {
              title: "Продавец",
              show: true,
              name: "seller",
              width: 200,
              filter: "select",
              type: "link",
              getLink: (item) => {
                  return {
                      route: {
                          name: "SellerInfo",
                          params: {
                              s_id: item.s_id,
                          },
                          query: {
                              date: this.reportSettings.date,
                              date2: this.reportSettings.date2,
                              fbs: this.reportSettings.fbs,
                          },
                      },
                      text: item.seller,
                      target: "_blank",
                  };
              },
          },
      ]);
      */

      return a.map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};