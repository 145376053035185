import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import AppTabs from "@/components/AppTabs.vue";
import { FadeTransition } from "vue2-transitions";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import CellItem from "@/components/Cell/Item.vue";
import CellItemMulti from "@/components/Cell/ItemMulti.vue";
import CellList from "@/components/Cell/List.vue";
import LargeChart from "@/components/charts/LargeChart.vue";
import LoadingOverlay from "@/components/Loading/Overlay";
export default {
  components: {
    AppTabs,
    FadeTransition,
    AppSelectMulti,
    CellItem,
    CellItemMulti,
    CellList,
    LargeChart,
    LoadingOverlay
  },
  props: {
    cells: {
      type: Object,
      required: true
    },
    loader: {
      type: Function,
      required: true
    },
    loaderConfig: {
      type: Object,
      required: true
    },
    period: {
      type: Array,
      required: true
    },
    noDynamic: {
      type: Boolean,
      default: false
    },
    noTabs: {
      type: Boolean,
      default: false
    },
    newTabs: {
      type: Array,
      default: () => []
    },
    forceLoading: {
      type: Boolean,
      default: false
    },
    //не рисовать cell при total=0
    qSkipCellIfNotLoaded: {
      type: {
        Number,
        Boolean
      },
      default: false
    }
  },
  data() {
    return {
      // tabs_charts: [
      //     {
      //         id: 1,
      //         title: "Сводка за период",
      //     },
      //     {
      //         id: 2,
      //         title: "Динамика показателей",
      //     },
      //     ...this.newTabs,
      // ],
      tabs_charts_active: null,
      select_chart_model: this.cells[Object.keys(this.cells)[0]].multi ? [this.cells[Object.keys(this.cells)[1]]] : [this.cells[Object.keys(this.cells)[0]]],
      loaded: null,
      loading: false,
      error: false
    };
  },
  computed: {
    tabs_charts() {
      let tabs = [{
        id: 1,
        title: "Сводка за период"
      }, {
        id: 2,
        title: "Динамика показателей",
        hidden: this.noDynamic
      }, ...this.newTabs];
      return tabs;
    },
    showFirstTab() {
      var _this$tabs_charts_act, _this$tabs_charts$, _this$tabs_charts_act2;
      return ((_this$tabs_charts_act = this.tabs_charts_active) === null || _this$tabs_charts_act === void 0 ? void 0 : _this$tabs_charts_act.id) == ((_this$tabs_charts$ = this.tabs_charts[0]) === null || _this$tabs_charts$ === void 0 ? void 0 : _this$tabs_charts$.id) || ((_this$tabs_charts_act2 = this.tabs_charts_active) === null || _this$tabs_charts_act2 === void 0 ? void 0 : _this$tabs_charts_act2.tabToShow) == 1;
    },
    select_chart_items() {
      const result = {};
      Object.keys(this.cells).forEach(key => {
        if (!this.cells[key].multi) {
          result[key] = this.cells[key];
        }
      });
      return result;
    },
    chartDatasets() {
      return this.select_chart_model.map(selectItem => {
        const cell = this.cells[selectItem.id];
        return {
          color: cell.color,
          data: this.getLoaded(selectItem.id).dynamic,
          unit: cell.unit,
          title: cell.title
        };
      });
    }
  },
  methods: {
    onCellClick(type) {
      if (!this.noDynamic) {
        this.tabs_charts_active = this.tabs_charts[1];
        this.select_chart_model = [this.cells[type]];
      }
    },
    getLoaded(type) {
      if (!this.loaded || !this.loaded[type]) {
        return {
          total: null,
          dynamic: []
        };
      }

      //console.log(`184: this.loaded[${type}]=`, this.loaded[type])

      return this.loaded[type];
    },
    getSign(key) {
      const cell = this.cells[key];
      if (cell.percentOf) {
        const v = this.getLoaded(key).total || 0;
        const base = this.getLoaded(cell.percentOf).total;
        const percent = base ? Number(v / base * 100).toFixed(0) : null;
        return percent ? `(${percent}%)` : null;
      }
      return cell.unit;
    },
    getMulti(type) {
      //console.log(`209: getMulti(${type})`)

      return this.cells[type].multi.map(cell => {
        return {
          title: cell.title,
          info: this.getLoaded(cell.id).total,
          infoMin: cell.unit
        };
      });
    }
  },
  watch: {
    loaderConfig: {
      immediate: true,
      async handler(v, old) {
        if (JSON.stringify(v) === JSON.stringify(old)) {
          return;
        }
        this.loading = true;
        this.error = false;
        try {
          this.loaded = await this.loader();
        } catch (e) {
          this.loading = false;
          this.error = true;
          throw e;
        }
        this.loading = false;
      }
    },
    tabs_charts_active() {
      this.$emit("updateActiveTab", this.tabs_charts_active);
    },
    async forceLoading() {
      this.loading = true;
      this.error = false;
      try {
        this.loaded = await this.loader();
      } catch (e) {
        this.loading = false;
        this.error = true;
        throw e;
      }
      this.loading = false;
    },
    cells() {
      this.select_chart_model = [this.cells[Object.keys(this.cells)[0]]];

      //console.log('240: this.select_chart_model=',this.select_chart_model)
    }
  }
};