import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import AppSelect from "@/components/AppSelect.vue";
export default {
  props: {
    value: {
      type: null,
      default: undefined
    },
    range: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() {
        return this.value === undefined ? "" : this.range.find(item => item.id === this.value);
      },
      set(v) {
        this.$emit("input", [""].includes(v) ? undefined : v.id);
      }
    }
  },
  components: {
    AppSelect
  }
};