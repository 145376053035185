var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "filters-select"
  }, [_c('app-select', {
    attrs: {
      "items": _vm.range,
      "fullwidth": ""
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };