var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fire-alert"
  }, [_c('div', {
    staticClass: "fire-alert__content",
    class: {
      light: _vm.light
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": "Flame"
    }
  }), _vm._m(0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "fire-alert__text"
  }, [_vm._v(" Важно! С 1 февраля 2025 вступают в силу новые цены на тарифы "), _c('br'), _c('br'), _vm._v(" Тариф Start - 7 000 р. / месяц"), _c('br'), _vm._v(" Тариф Business - 12 000 р. / месяц"), _c('br'), _vm._v(" Тариф Pro - 25 000 р. / месяц"), _c('br'), _c('br'), _vm._v(" Успейте купить подписку на долгий срок по старым ценам! ")]);
}];
export { render, staticRenderFns };