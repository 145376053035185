import "core-js/modules/esnext.iterator.map.js";
import { dateFormat, dateTimeFormat, dateTimeLocalFormat } from "@/utils/dates";
import AppSelect from "@/components/AppSelect.vue";
export default {
  components: {
    AppSelect
  },
  props: {
    h: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: false,
      default: 1
    },
    rawResponse: {
      type: Object,
      required: false
    }
  },
  emits: ["refresh"],
  methods: {
    wb7p_image(p_image) {
      if ((p_image.indexOf("wbbasket.ru") >= 0 || p_image.indexOf("basket-") >= 0 && p_image.indexOf("wb.ru") >= 0) && p_image.slice(-4) == ".jpg") {
        p_image = p_image.slice(0, -4) + ".webp";
      }
      if (p_image.slice(0, 6) != "https:") {
        p_image = "https:" + p_image;
      }
      return p_image;
    },
    getActionsItems(column) {
      return column.actions.map((action, i) => {
        return {
          id: i + 1,
          title: action.title,
          action: action.action,
          disabled: action.disabled || false
        };
      });
    },
    onActionSelect(item, action) {
      action.action(item, {
        refresh: () => this.$emit("refresh")
      });
    },
    formatDate(v) {
      return dateFormat(v);
    },
    formatDateTime(v) {
      return dateTimeFormat(v);
    },
    formatDateTimeLocal(v) {
      return dateTimeLocalFormat(v);
    }
  }
};