var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('transition', [_c('div', {
    staticClass: "warning"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/warning-connect.svg`),
      "alt": "Warning"
    }
  }), _c('p', {
    staticClass: "warning__text"
  }, [_vm._v(" Активируйте бесплатный демо-доступ ко всем инструментам сервиса ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto warning-connect-btn",
    attrs: {
      "disabled": _vm.ordered
    },
    on: {
      "click": _vm.openModal
    }
  }, [!_vm.ordered ? _c('span', [_vm._v("Активировать")]) : _c('span', [_vm._v("Демо-доступ заказан")])])])]), _c('modal-self-demo', {
    ref: "ModalSelfDemo",
    on: {
      "complete": _vm.onCompleted
    }
  }), _c('modal-self-demo-success', {
    ref: "ModalSelfDemoSuccess",
    on: {
      "complete": _vm.onCompletedSuccess
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };