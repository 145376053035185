export default {
  name: "FireAlert",
  props: {
    light: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      //default: require("@/assets/images/icons/flame.svg"),
      default: require("@/assets/images/icons/increase.svg")
    }
  }
};