import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
import _ from "lodash";
import { toExportFormat } from "@/utils/export";
import { dateRangeDays } from "@/utils/dates";
import { formatNumber } from "@/utils/numbers";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      oldFilters: {},
      additionalColumns: []
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      let filters = null;
      const keys = Object.keys(query);
      if (keys.some(key => key.startsWith("f."))) {
        filters = keys.filter(key => key.startsWith("f.")).reduce((acc, key) => {
          acc[key] = query[key];
          return acc;
        }, {});
      }
      const body = {
        ...query,
        ...this.reportSettings
      };
      if (filters) {
        body.filters = filters;
      }
      if (!_.isEqual(filters, this.oldFilters)) {
        this.$emit("updateFilters", filters);
      }
      this.oldFilters = {
        ...filters
      };
      // try {
      const data = await this.$store.dispatch("product/getCompareCategories", body);
      // Для фильтров средней позиции и последней позиции
      let additionalColumns = [{
        title: `Средняя позиция`,
        show: false,
        name: `avg_position`,
        width: 140,
        type: "number",
        filter: "numberRange",
        sort: false
      }, {
        title: `Последняя позиция`,
        show: false,
        name: `last_position`,
        width: 140,
        type: "number",
        filter: "numberRange",
        sort: false
      }];
      if (data.items.length) {
        const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
        // Добавляем колонки для каждого товара и настраиваем саб хедер таблицы
        data.items[0].products.forEach((item, index) => {
          const product = {
            p_id: item[0],
            title: item[1],
            image: item[2],
            link: item[3]
          };
          additionalColumns = [...additionalColumns, {
            title: `Средняя позиция за ${days} дней`,
            show: true,
            name: index ? `avg_position${index}` : "avg_position",
            width: 220,
            sort: !index,
            type: "number",
            subHeadColspan: 2,
            subHeadItem: product,
            subHeadColumn: {
              type: "link",
              sort: false,
              title: "Товар",
              show: true,
              name: "title",
              width: 500,
              filter: "text",
              noOverflow: true,
              style: {
                position: "sticky",
                background: "#fff",
                "z-index": 1,
                left: "0px"
              },
              lastFixed: true,
              getImage: item => item.image,
              getLink: item => {
                return {
                  route: {
                    name: "ProductInfo",
                    params: {
                      mp: this.reportSettings.mp,
                      p_id: item.p_id
                    },
                    query: {
                      date: this.reportSettings.date,
                      date2: this.reportSettings.date2,
                      fbs: this.reportSettings.fbs
                    }
                  },
                  text: item.title,
                  target: "_blank",
                  slotRight: `<br/><a href="${item.link}" target="_blank" style="display:block;">${item.p_id}</a>`
                };
              }
            }
          }, {
            title: "Последняя позиция",
            show: true,
            name: index ? `last_position${index}` : "last_position",
            width: 160,
            sort: !index,
            type: "number",
            subHeadColspan: 0
          }];
        });
        if (query.action !== "export") {
          this.additionalColumns = additionalColumns;
        }
        data.items.forEach(item => {
          item.products.forEach((product, index) => {
            item[index ? `avg_position${index}` : "avg_position"] = product[4];
            item[index ? `last_position${index}` : "last_position"] = product[5];
          });
        });
        // Добавляем разделение чисел по разрядам
        data.items.forEach(item => {
          Object.keys(item).forEach(key => {
            if (!["p_id", "sku"].includes(key)) {
              item[key] = formatNumber(item[key]);
            }
          });
        });
        // Для фильтра ownSkuExist
        data.ranges = {
          ownSkuExist: [{
            id: false,
            title: "Нет"
          }, {
            id: true,
            title: "Да"
          }]
        };
      }
      if (query.action === "export") {
        const firstRow = {
          ...data.items[0]
        };
        firstRow.products.forEach((item, index) => {
          const key = index ? `avg_position${index}` : "avg_position";
          firstRow[key] = `${item[0]}`;
        });
        const columns = [...this.columns, ...additionalColumns];
        columns.forEach(column => {
          if (!column.name.startsWith("avg_position")) {
            firstRow[column.name] = "";
          }
        });
        data.items = [firstRow, ...data.items];
        return toExportFormat(data, columns);
      } else {
        return data;
      }
      // } catch (e) {
      //     const errorMessage = e.message;
      //     if (
      //         typeof errorMessage === "string" &&
      //         errorMessage !== "no-limit-error"
      //     ) {
      //         this.$emit("limitReached", errorMessage);
      //     }
      // }
    }
  },
  computed: {
    columns() {
      return [{
        title: "Категория",
        show: true,
        name: "c_name_path",
        width: 440,
        type: "slot",
        filter: "tags",
        subHeadColspan: 1
      }, {
        title: "Ваш товар виден",
        show: true,
        name: "ownSkuExist",
        filter: "selectLocalSingle",
        width: 140,
        type: "slot",
        subHeadColspan: 1
      }, ...this.additionalColumns];
    }
  }
};