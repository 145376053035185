import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import colors from "@/utils/colors";
import numbers from "@/utils/numbers";
import LineChart from "@/components/charts/LineChart.vue";
dayjs.extend(customParseFormat);
export default {
  components: {
    LineChart
  },
  props: {
    datasets: {
      type: Array
    },
    period: {
      type: Array
    },
    period2: {
      type: Array
    },
    oneScale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelsets: [],
      refresh: 0
    };
  },
  watch: {
    chartData() {
      this.refresh++;
    }
  },
  methods: {
    addLabels(firstDay2, labels, length) {
      this.labelsets[0] = labels;
      let arr = [];
      for (let i = 0; i < length; i++) {
        arr.push(firstDay2.add(i, "day").format("DD.MM.YYYY"));
      }
      this.labelsets[1] = arr;
    }
  },
  computed: {
    chartData() {
      const length = this.datasets[0] ? this.datasets[0].data.length : 0;
      const firstDay = dayjs(this.period[0], "YYYY-MM-DD");
      let labels = [];
      for (let i = 0; i < length; i++) {
        labels.push(firstDay.add(i, "day").format("DD.MM.YYYY"));
      }
      const legend1 = [];
      const legend2 = [];
      if (this.period2) {
        this.period.forEach((item, index) => {
          legend1.push(dayjs(this.period[index], "YYYY-MM-DD").format("DD.MM.YYYY"));
          legend2.push(dayjs(this.period2[index], "YYYY-MM-DD").format("DD.MM.YYYY"));
        });
      }
      return {
        labels,
        datasets: this.datasets.map((dataset, i) => {
          if (this.period2 && dataset.color) {
            return {
              yAxisID: `yAxis0`,
              borderColor: colors[dataset.color].color,
              pointBackgroundColor: colors[dataset.color].color,
              pointBorderColor: "#FFFFFF",
              backgroundColor: "gradient",
              data: dataset.data,
              label: i === 0 ? legend1[0] + " - " + legend1[1] : legend2[0] + " - " + legend2[1]
              // pointStyle: 'circle',
              // pointRadius: 5,
            };
          }
          if (dataset.color) {
            return {
              yAxisID: `yAxis${this.oneScale ? 0 : i}`,
              borderColor: colors[dataset.color].color,
              pointBackgroundColor: colors[dataset.color].color,
              pointBorderColor: "#FFFFFF",
              backgroundColor: "gradient",
              data: dataset.data
            };
          }
          return {
            yAxisID: `yAxis${this.oneScale ? 0 : i}`,
            borderColor: "#FAAB31",
            pointBackgroundColor: "#FAAB31",
            pointBorderColor: "#FFFFFF",
            backgroundColor: "gradient",
            data: dataset.data
          };
        })
      };
    },
    chartOptions() {
      let scales = [];
      const scales2 = [];
      let legend = false;
      if (this.period2) {
        legend = {
          position: "bottom",
          labels: {
            usePointStyle: true
          }
        };
        scales[0] = {
          id: `yAxis0`,
          type: "linear",
          ticks: {
            // suggestedMin: 0,
            callback: function (value) {
              return numbers.format(value);
            }
          }
        };
        scales2[0] = {
          id: `xAxis${0}`,
          display: false
        };
      } else {
        if (this.oneScale) {
          scales.push({
            id: `yAxis0`,
            type: "linear",
            ticks: {
              callback: function (value) {
                return numbers.format(value);
              }
            },
            scaleLabel: this.datasets[0].title
          });
        } else {
          this.datasets.forEach((dataset, i) => {
            scales.push({
              id: `yAxis${i}`,
              type: "linear",
              ticks: {
                callback: function (value) {
                  return numbers.format(value);
                },
                fontColor: colors[dataset.color].color
              },
              scaleLabel: dataset.title
            });
            if (this.period2) {
              scales2.push({
                id: `xAxis${i}`,
                display: false
              });
            }
          });
        }
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend,
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          yAxes: scales,
          xAxes: scales2
        },
        options: {
          plugins: {
            legend
          }
        },
        tooltips: {
          intersect: false,
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.6)",
          caretSize: 0,
          backgroundColor: "#ffffff",
          xPadding: 16,
          yPadding: 12,
          displayColors: false,
          titleFontFamily: "Ubuntu",
          titleFontSize: 14,
          titleFontColor: "rgba(0, 0, 0, 0.6)",
          titleFontStyle: "normal",
          bodyFontFamily: "Ubuntu",
          bodyFontSize: 14,
          bodyFontColor: "rgba(0, 0, 0, 0.9)",
          bodyFontStyle: "bold",
          callbacks: {
            label: data => {
              let formatted = numbers.divided(data.value);
              const unit = this.datasets[data.datasetIndex].unit;
              const title = this.datasets[data.datasetIndex].title;
              if (unit) {
                formatted = `${formatted} ${unit}`;
              }
              return `\n ${title}: ${formatted}`;
            },
            title: data => {
              const length = this.datasets[0] ? this.datasets[0].data.length : 0;
              const firstDay = dayjs(this.period[0], "YYYY-MM-DD");
              const firstDay2 = this.period2 ? dayjs(this.period2[0], "YYYY-MM-DD") : false;
              let labels = [];
              for (let i = 0; i < length; i++) {
                labels.push(firstDay.add(i, "day").format("DD.MM.YYYY"));
              }
              if (firstDay2) {
                this.addLabels(firstDay2, labels, length);
                labels = this.labelsets;
                return `${labels[data[0].datasetIndex][data[0].index]}`;
              }
              return `${data[0].label}`;
            }
          }
        }
      };
    }
  }
};